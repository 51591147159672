// @refresh reset
import React, {Component} from 'react'
import * as PIXI from 'pixi.js'
import {find, get, mapValues, reduce} from 'lodash'
import Smile from './smile.js'
import Footer from './footer'
import mobileCheck from '../utils/mobileCheck'

class Stage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            width: 800,
            height: 800,
            loaded: typeof document === 'undefined' ? true : document.readyState ===
                'complete',
        }

        this.mousePosition = {
            x: 0,
            y: 0,
        }

        this.handleLoad = this.handleLoad.bind(this)

        this.viewRef = React.createRef()
        this.scrollRef = React.createRef()
        this.tickerHandlerRef = React.createRef()

        this.onViewRef = (element) => {
            if (!element) {
                return null;
            }

            this.viewRef.current = element;

            const {width, height} = this.state

            this.app = new PIXI.Application({
                width: width,
                height: height,
                antialias: true,
                resolution: 2,
                autoResize: true,
            })

            element.appendChild(this.app.view);

            this.app.ticker.stop()
            if (typeof this.tickerHandlerRef.current === 'function') {
                this.app.ticker.remove(this.tickerHandlerRef.current)
            }

            //remove children from stage

            const findImage = (data, relativePath) => get(
                find(
                    get(data, 'data.allFile.edges'),
                    {
                        node: {
                            relativePath,
                        },
                    },
                ),
                'node.publicURL',
            )

            const getImage = (path) => {
                const src = findImage(this.props, path);
                if (!src) {
                    throw Error(`Image not found: ${path}`);
                }

                let image = new Image()
                image.src = src;
                PIXI.Texture.addToCache(
                    new PIXI.Texture(
                        new PIXI.BaseTexture(image),
                    )
                    , path)
                const sprite = PIXI.Sprite.from(path)
                sprite.name = path
                return sprite
            }

            const parallax = (depth) => {
                return {
                    x: -this.mousePosition.x * depth,
                    y: -this.mousePosition.y * depth,
                }
            }

            const getChild = (name) => this.app.stage.getChildByName(name)

            const getOffset = {
                top: (el) => !!el ? el.height + el.y : 0,
                left: (el) => !!el ? el.width + el.x : 0,
            }

            const children = {
                bg_color: {
                    init: () => new PIXI.Graphics(),
                    render: (graphics, {width, height}) => {
                        graphics.clear()
                        graphics.beginFill(0x00aaff, 1)
                        graphics.drawRect(0, 0, this.state.width, this.state.height)
                        graphics.endFill()
                        return graphics
                    },
                },
                bg: {
                    init: () => getImage('2024_march/background.webp'),
                    render: (graphics, {width, height, scroll}) => {

                        const [scrollBy, scrolled] = scroll;

                        const ratio = graphics.texture.orig.width /
                            graphics.texture.orig.height

                        const aspect = (width / height) /
                            (this.state.width / this.state.height)

                        const depth = 1
                        const p = parallax(depth)
                        const w = this.state.width
                        const h = (aspect * w / ratio)
                        const x = 0
                        const y = 0

                        graphics.x = x;
                        graphics.y = (this.state.height * scrollBy * -scrolled) + y;
                        graphics.width = w;
                        graphics.height = h;

                        return graphics
                    },
                },
                /*

                 */
                /*
                text: {
                  init: () => getImage('2023_june/text.webp'),
                  render: (graphics, { width, height, scroll }) => {

                    const ratio = graphics.texture.orig.width /
                      graphics.texture.orig.height
                    const aspect = (width / height) /
                      (this.state.width / this.state.height)

                    const depth = 10
                    const p = parallax(depth)
                    const w = 1 * this.state.width + (2 * depth)
                    const h = (aspect * w / ratio) + (2 * depth)
                    const x = 0 * this.state.width + p.x - depth
                    const y = 0 * this.state.width + p.y - depth

                    graphics.x = x
                    graphics.y = -(1 * this.state.height * scroll) + y
                    graphics.width = w
                    graphics.height = h

                    return graphics
                  },

                },
                 */
            }

            const getScroll = () => {
                const element = this.scrollRef.current;
                if (!element == null || !element.scrollTop || !element.scrollHeight || !element.offsetHeight) {
                    return [0, 0];
                }
                return [(element.scrollHeight / element.offsetHeight) - 1, element.scrollTop / (element.scrollHeight - element.offsetHeight)];
            }

            this.app.ticker.stop()
            if (typeof this.tickerHandlerRef.current === 'function') {
                this.app.ticker.remove(this.tickerHandlerRef.current)
            }

            while (this.app.stage.children[0]) {
                this.app.stage.removeChild(this.app.stage.children[0])
            }

            const displaceTexture = getImage('2023_march/displace.jpg')
            const filter = new PIXI.filters.DisplacementFilter(displaceTexture)
            filter.scale.set(8)
            filter.resolution = 2
            this.app.stage.filterArea = this.app.screen

            this.app.stage.filters = [filter]

            const objects = reduce(children, (acc, child) => {

                const object = child.init()

                const action = (object) => () => {

                    const size = {width: this.rect().width, height: this.rect().height}
                    const scroll = {scroll: getScroll()}
                    const args = {...size, ...scroll}

                    return child.render(object, args)

                }

                return [...acc, action(object)]

            }, [])

            objects.forEach(object => {
                this.app.stage.addChild(object())
            })

            this.app.render()

            this.app.ticker = PIXI.Ticker.shared

            const tickerHandler = (deltaTime) => {
                objects.forEach(object => {
                    object()
                })
                this.app.render()
            }
            this.tickerHandlerRef.current = tickerHandler

            this.app.ticker.add(tickerHandler)
            this.app.ticker.start()

            this.setRatio()
            window.addEventListener('resize', this.updateDimensions)
            window.addEventListener('load', this.handleLoad)
            if (!mobileCheck()) {
                window.addEventListener('mousemove', this.setMousePosition)
            }

        }

    }

    rect() {
        return this.viewRef.current.getBoundingClientRect() ??
            {width: 0, height: 0, x: 0, y: 0}
    }

    setRatio = () => {

        const style = {
            width: this.rect().width + 'px',
            height: this.rect().height + 'px',
        }

        mapValues(style, (v, k) => {

            this.app.view.style[k] = v

        })
    }

    setMousePosition = (event) => {
        this.mousePosition = mapValues({x: event.clientX, y: event.clientY},
            (v, k) => {
                const w = k === 'x' ? 'innerWidth' : 'innerHeight'
                return 2 * (v - (0.5 * window[w])) / window[w]
            })
    }

    updateDimensions = () => {
        this.setRatio()
        this.forceUpdate()
    }

    componentWillUnmount() {
        this.app.ticker.stop()
        if (typeof this.tickerHandlerRef.current === 'function') {
            this.app.ticker.remove(this.tickerHandlerRef.current)
        }
        this.app = null
        window.removeEventListener('resize', this.updateDimensions)
        if (!mobileCheck()) {
            window.removeEventListener('mousemove', this.setMousePosition)
        }
        window.removeEventListener('load', this.handleLoad)
    }

    handleLoad() {
        setTimeout(() => {
            this.setState({
                loaded: true,
            })
        }, 2000)

    }

    render() {
        return (

            <div className="poster">
                <div className={'loader ' + (this.state.loaded ? '' : 'active')}>
                    <Smile/>
                </div>
                <div ref={this.scrollRef} className="scroll">
                    <div className="scroll-content">
                        <div className="footer-wrapper"><Footer/></div>
                    </div>
                </div>
                <div ref={this.onViewRef} className={'stage '}></div>
            </div>
        )
    }
}

export default Stage

