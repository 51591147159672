import React from "react"

function Smile () {
  return <svg className="smile-svg" xmlns="http://www.w3.org/2000/svg"
              viewBox="-90 -90 200 200">
    <defs>
      <filter id="filter">
        <animate
          xlinkHref="#turbulence"
          id="anim-dialiate"
          attributeName="seed"
          from="0"
          to="30"
          dur="3s"
          fill="freeze"
          repeatCount="indefinite"
        />
        <feTurbulence
          id="turbulence"
          type="fractalNoise"
          seed="0"
          baseFrequency="0.03 0.03"
          numOctaves="1"
          result="warp">
        </feTurbulence>
        <feDisplacementMap
          id="map"
          xChannelSelector="R"
          yChannelSelector="G"
          scale="50"
          in="SourceGraphic"
          in2="warpOffset"
        />
      </filter>
    </defs>
    <g filter="url(#filter)">
      <ellipse className="eyes" cx="12" cy="12.5" rx="1" ry="3.5"/>
      <ellipse className="eyes" cx="24" cy="12.5" rx="1" ry="3.5"/>
      <path d="M45.73,39c0,8.84-3.8,16-8.5,16s-8.5-7.16-8.5-16"
            transform="translate(-18.73 -16)" fill="none" stroke="#000"
            strokeMiterlimit="10"/>
      <ellipse cx="18.5" cy="23" rx="18" ry="22.5" fill="none" stroke="#000"
               strokeMiterlimit="10"/>
    </g>
  </svg>
}

export default Smile
